.programHeader {
  color: #FFD636 !important;
}

.sideWrapper {
  background: #2C5697;
  font-family: Helvetica, sans-serif;
}

.program {
  background-color: #2C5697 !important;
  color: #fff !important;
  border: 1px solid #fff;
}

.galleryHeader {
  & h3 {
    color: #FFD636 !important;
  font-size: 1.2rem;
  }

  & p {
    color: #fff;
  }
}

.nextSlide, .prevSlide {
  border-color: #fff !important;
}

.noContentTitle {
  color: #fff !important;
}

.divider {
  border: 1px solid #2C5697;
}

.customText {
  h3 {
    font-size: 0.9rem;
    color: #FFD636;
  } 

  p {
    font-size: 0.7rem;
    color: #fff;
  }
}

.filterBtn {

  button {
    background: #2C5697;
    color: #FFD636;
    border: 1px solid #FFD636;
  }

  .activeBtn {
    background: #FFD636;
    color: #2C5697;
  }
}

.iconContainer {
  display: none;
}