@import '../../../globals/colors.module.scss';
@import '../../../globals/borderRadius.module.scss';

.declinedWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: var(--color-white);
  z-index: 10;
  position: absolute;
  flex-flow: column nowrap;
  padding: 10px 20px 20px 20px;
  width: 100%;
  max-width: 400px;
  top: 20%;
  left: 36%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  & .participantBackground {
    background: url('../../../img/hexagonBgFuzzy.png') no-repeat center center;
    background-size: 100%;
    border-radius: 12px;
    padding: 10px 10px 0 10px;
    height: 140px;
    width: 100%;
  }

  & .close {
    display: flex;
    margin: 40px 0 20px 0;
    font-size: 1.2rem;
    padding: 14px 20px;
    color: var(--color-white);
    background-color: var(--color-blue);
    cursor: pointer;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  & .declinedNotification {
    position: absolute;
    top: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--color-white);
    padding: 20px;
  }
}
