@import "../../../globals/colors.module.scss";
@import "../../../globals/borderRadius.module.scss";

.container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}

.participantsWrapper {
  display: inline-flex;
  flex-direction: column;
  width: 310px;
}

.participantsTopBar {
  position: absolute;
  text-align: left;
  padding: 16px 24px;
  font-size: 1.2rem;
  margin-top: -55px;
  margin-left: 40px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-white);
}

.searchWrapper {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  height: 62px;
  margin-top: -59px;
  padding-left: 320px;
  transition: all 0.3s ease;
  z-index: 5;

  .searchBar {
    background: var(--color-white);
    border-radius: 3px;
    border: none;
    outline: none;
    color: var(--color-blue);
    height: 26px;
    width: 90%;
    text-align: center;
  }

  .clearSearchButton {
    padding: 10px;
    margin-left: 20px;
    background: var(--color-grey);
    border-radius: var(--borderRadius-soft);
    color: var(--color-blue);
    align-self: center;
    justify-self: center;
    cursor: pointer;
    border: none;
    outline: none;
  }
}

.participantCard {
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 10px;
  height: 45px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);

  color: var(--color-white);
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  .participantInfo {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
  }
}

.participantCardYou {
  display: flex;
  align-items: center;

  &::after {
    margin-left: 5px;
    content: "(you)";
  }

  & .participantInfo {
    font-weight: 700 !important;
    font-size: 1.3rem;
  }
}

.participantsList,
.extraParticipantsWrapper {
  overflow-y: auto;
  flex-grow: 1;
}

.videoChat {
  display: flex;
  background: url("../../../img/information.png") no-repeat center center;
  background-size: 100%;
  height: auto;
  width: 26px;
  padding: 10px 0;
  z-index: 10;
}

.name {
  display: block;
  font-size: 1rem;
  font-weight: 600;
}

.information {
  display: flex;
  background: url("../../../img/information.png") no-repeat center center;
  background-size: 100%;
  height: auto;
  width: 36px;
  padding: 20px 0 20px 0;
  margin-left: 10px;
  z-index: 10;
  cursor: pointer;
}

.placeholderImage {
  display: flex;
  height: 32px;
  width: 32px;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: var(--borderRadius-round);
  color: var(--color-blue);
  background: var(--color-white);
  justify-content: center;
  align-items: center;
}

.participantInfoWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 20px;
  align-items: flex-end;
}

.participantInfoHandlerWrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: flex-start;
}

.participantsList {
  overflow-y: auto;
}
