@import '../../globals/colors.module.scss';
@import '../../globals/borderRadius.module.scss';

.programWrapper {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.program {
  display: flex;
  font-size: 0.9rem;
  background: var(--color-blue);
  padding: 10px;
  color: var(--color-white);
  margin: 14px 0;
  border-radius: var(--borderRadius-hard);
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  div {
    &:nth-child(1) {
      &::after {
        content: '|';
        color: var(--color-white);
        font-weight: 600;
        margin: 0 10px;
      }
    }
  }
}

.liveProgram {
  display: flex;
  font-size: 0.9rem;
  background: var(--color-blue);
  padding: 10px;
  color: var(--color-white);
  margin: 14px 0;
  border-radius: var(--borderRadius-hard);
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  div {
    &:nth-child(1) {
      &::after {
        content: '|';
        color: var(--color-white);
        font-weight: 600;
        margin: 0 10px;
      }
    }
  }
}

.noContentWrapper {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 20px;
}

.noContentTitle {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.programText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.liveIndicatorBlock {
  position: absolute;
  left: 0px;
  top: -28px;

  .liveIndicator {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-red);
    color: #fff;
    padding: 6px 7px;
    border-radius: var(--borderRadius-hard);
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: bold;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
