@import '../../../globals/colors.module.scss';
@import '../../../globals/borderRadius.module.scss';

.waitingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: var(--color-white);
  z-index: 10;
  position: absolute;
  flex-flow: column nowrap;
  padding: 10px 20px 20px 20px;
  width: 100%;
  max-width: 400px;
  top: 20%;
  left: 36%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  & .callIconWrapper {
    display: flex;
    padding: 20px;
    border-radius: var(--borderRadius-round);
    background-color: var(--color-blue);
    animation: hoverWave linear 2s infinite;
    position: absolute;
    border: 4px solid var(--color-white);
    top: 80px;
  }

  & .callIcon {
    display: flex;
    background: url('../../../img/telephone-call.png') no-repeat center center;
    background-size: 100%;
    height: auto;
    width: 36px;
    padding: 20px 0;
    animation: shake linear 2s infinite;
  }

  & .participantNameWrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 40px;
    align-items: center;
    padding: 20px;
  }

  & .calling {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 20px;
  }

  & .participantName {
    font-size: 1.6rem;
    font-weight: 600;
  }

  & .declineCall {
    display: flex;
    margin: 40px 0 20px 0;
    font-size: 1.2rem;
    padding: 14px 20px;
    color: var(--color-white);
    background-color: var(--color-blue);
    cursor: pointer;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  & .participantBackground {
    background: url('../../../img/hexagonBgFuzzy.png') no-repeat center center;
    background-size: 100%;
    border-radius: 12px;
    padding: 10px 10px 0 10px;
    height: 140px;
    width: 100%;
  }
}

@keyframes hoverWave {
  0% {
    box-shadow: 0 8px 10px rgba(35, 22, 81, 0.3), 0 0 0 0 rgba(35, 22, 81, 0.2),
      0 0 0 0 rgba(35, 22, 81, 0.2);
  }

  40% {
    box-shadow: 0 8px 10px rgba(35, 22, 81, 0.3),
      0 0 0 15px rgba(35, 22, 81, 0.2), 0 0 0 0 rgba(35, 22, 81, 0.2);
  }

  80% {
    box-shadow: 0 8px 10px rgba(35, 22, 81, 0.3), 0 0 0 30px rgba(35, 22, 81, 0),
      0 0 0 26.7px rgba(35, 22, 81, 0.067);
  }

  100% {
    box-shadow: 0 8px 10px rgba(35, 22, 81, 0.3), 0 0 0 30px rgba(35, 22, 81, 0),
      0 0 0 40px rgba(35, 22, 81, 0);
  }
}

@keyframes shake {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
