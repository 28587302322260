@import "../../../globals/borderRadius.module.scss";

.loungeMeetingsWrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: var(--font-nunito);
  border-radius: 5px;

}

.meetingsTopBar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--color-secondary-blue);
  text-align: left;
  padding: 16px 24px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-white);
}

.loungeMeetingsList {
  overflow-y: auto;
}
