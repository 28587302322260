.noChatListWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.noChatListImage {
  background: url('../../img/no-chats.svg') no-repeat center center;
  background-size: contain;
  width: 100%;
  height: 500px;
}

.noChatListText {
  margin-top: 20px;
  padding: 20px;
  font-size: 1.2rem;
}
