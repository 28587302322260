@import '../../globals/colors.module.scss';
@import '../../globals/borderRadius.module.scss';

.chatListWrapper {
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 50px;
  align-content: center;
  flex-flow: column nowrap;
  color: var(--color-blue);
  overflow-x: hidden;
}

.chatListElement {
  display: flex;
  padding: 10px;
  margin: 20px auto;
  border: 1px solid var(--color-blue);
  border-radius: 12px;
  width: 80%;
  cursor: pointer;
}

.chatListHeader {
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 20px 0;
  justify-content: center;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.newMessageNotification {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-color: #ffbb36;
    padding: 5px;
    border: 5px solid var(--color-white);
    width: 10px;
    height: 10px;
    right: -10px;
    top: -10px;
    border-radius: var(--borderRadius-round);
  }
}

.online {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background-color: #00b300;
    padding: 2px;
    border: 5px solid var(--color-white);
    width: 10px;
    height: 10px;
    left: -10px;
    bottom: -10px;
    border-radius: var(--borderRadius-round);
  }
}
