@import '../../globals/colors.module.scss';

.galleryWrapper {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.galleryHeader {
  font-weight: 600;

  h3 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.7rem;
    font-weight: 500;
  }
}

.carouselItem {
  cursor: pointer;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.swiper {
  margin-bottom: 40px;

  .swiper-button-next {
    &::after {
      color: red !important;
    }
  }
}

.swiperDot {
  background: var(--color-blue);
  opacity: 1;
  transform: scale(1) !important;
}

.activeSwiperDot {
  background: var(--color-yellow);
  opacity: 1;
  transform: scale(1) !important;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
}

.prevSlide {
  position: absolute;
  bottom: -10px;
  left: 60px;
  cursor: pointer;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.nextSlide {
  position: absolute;
  bottom: -10px;
  right: 60px;
  cursor: pointer;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
