@import '../../globals/colors.module.scss';

.scoreboardWrapper {
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  border-radius: 6px;
  margin: 20px 0 10px;
  height: auto;
  overflow: hidden;
}

.scoreboardHeader {
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 600;
}

.video,
.image {
  border-radius: 6px;
  height: 144px;
  width: 100%;
}

.image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
