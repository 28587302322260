@import '../../globals/colors.module.scss';

.messageSenderWrapper {
  border-top: 1px solid var(--color-blue);
  width: 100%;
  padding: 20px 0;
  margin-top: 10px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  input {
    height: 30px;
    color: var(--color-blue);
    background-color: #e4efff;
    border: none;
    border-radius: 12px;
    padding: 6px 16px;
  }
}

.sendMessage {
  background: url('../../img/send-message.png') no-repeat center center;
  background-size: 26px;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.messageWarning {
  position: absolute;
  background-color: var(--color-white);
  border: 1px solid var(--color-blue);
  width: 100%;
  border-bottom: none;
  color: var(--color-blue);
  border-radius: 12px 12px 0 0;

  p {
    padding: 10px 20px;
    text-align: center;
  }
}
