@import '../../globals/colors.module.scss';

.programWrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  max-height: 350px;
  margin-bottom: 16px;
}

.programHeader {
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 600;
}

.filterBtn {
  display: flex;
  padding: 10px 0;
  font-size: 0.9rem;

  button {
    padding: 6px 10px;
    background: var(--color-red);
    color: var(--color-white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:first-of-type {
      background: var(--color-green);
      margin-right: 10px;
    }
  }

  .activeBtn {
    background: var(--color-green);
  }
}

.customText {
  h3 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.7rem;
  }
}

.programText {
  overflow: hidden;
  text-overflow: ellipsis;
}
