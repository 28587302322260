.programWrapper {
  height: 150px;
}

.programWrapperFullProgram {
  max-height: 300px;
}

.programHeader {
  color: #e22d2d;
}

.divider {
  border: 1px solid #e22d2d;
}

.program {
  background-color: #fff;
  color: #e22d2d;
  border: 1px solid #e22d2d;
}

.customText {
  h3 {
    font-size: 0.9rem;
    color: #e22d2d;
  }

  p {
    font-size: 0.7rem;
    color: #e22d2d;
  }
}

.noContentTitle {
  color: #e22d2d;
}

.galleryHeader {
  & h3 {
    color: #e22d2d;
  }

  & p {
    color: #e22d2d;
  }
}

.nextSlide,
.prevSlide {
  border-color: #e22d2d;
}

.iconContainer {
  background: #e22d2d;
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  padding: 4px;
}

.filterBtn {
  button {
    background: #fff !important;
    color: #e22d2d;
    border: 1px solid #e22d2d;

    &:first-of-type {
      background: #e22d2d;
    }
  }

  .activeBtn {
    background: #e22d2d;
    color: #fff;
  }
}
