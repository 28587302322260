:root {
  --color-white: #fff;
  --color-blue: #121c42;
  --color-secondary-blue: #16224f;
  --color-secondary-blue-opacity: rgba(0, 0, 0, 0.6);
  --color-third-blue: #1d2d6a;
  --color-green: #124238;
  --color-yellow: #ffbb36;
  --color-grey: #e8e5da;
  --color-secondary-grey: #5d6683;
  --color-dark-grey: #484f65;
  --color-red: #ea2429;
  --color-live: #02fe2a;
}
