@import "../../../globals/colors.module.scss";
@import "../../../globals/fonts.module.scss";


.lounge{
  display: flex;
  flex-direction: row;
  gap: 20px;

  .loungeParticipants {
    display: flex;
    flex-direction: column;
    width: 83vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    
    .loungeTopBar {
      display: flex;
      width: 100%;
      height: 60px;
      text-align: left;
      background-color: var(--color-secondary-blue);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    }
    .loungeWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 100%;
      font-family: var(--font-nunito);
    }
  }
  
}

.modalStyle {
  position: absolute;
  top: 17%;
  left: 92%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 1460px;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
}