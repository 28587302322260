.program {
  background-color: #10384f !important;
  color: #fff !important;
  border: 1px solid #fff;
  pointer-events: none;
}

.programHeader {
  color: #10384f !important;
}

.divider {
  border: 1px solid #10384f;
}

.filterBtn {
  display: none;
}

.iconContainer {
  display: none;
}

.programWrapper {
  max-height: 330px;
}
