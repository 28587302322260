.meetingCard {
  display: flex;
  margin: 12px;
  padding: 8px 2px;
  max-width: 276px;
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .imageContainer {
    display: flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;

    .image {
      height: 60px;
      width: 60px;
      border-radius: var(--borderRadius-round);
      border: 1px solid var(--color-white);
      background: var(--color-white);
      background-size: cover;
    }
  }
}

.meetingCardInfoWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
  align-items: left;
}

.meetingInformationContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 30px;
  justify-content: flex-end;

  .information {
    display: flex;
    flex-direction: row;
    height: 26px;
    width: 26px;
    z-index: 10;

    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: black;
      border-radius: 100%;
    }
  }

  .participantCount {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }
}

.name {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
}

.meetingCardInfoHandlerWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
}

.participantHover {
  display: flex;
  align-items: center;

  padding-right: 10px;
  text-align: right;
}


