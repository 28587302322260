@import '../../globals/colors.module.scss';

.matrixFailedWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.matrixFailedPicture {
  height: 50%;
  width: 100%;
  background: url('../../img/error.svg') no-repeat center center;
  background-size: contain;
}

.matrixFailedtitle {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  button {
    margin-top: 20px;
    padding: 12px 20px;
    background: var(--color-blue);
    color: var(--color-white);
    border: none;
    border-radius: 6px;
    width: auto;
    cursor: pointer;
  }
}
