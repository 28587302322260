.iconContainer {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    padding: 4px;
}

.iconAndLinkWrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.icon {
    cursor: pointer;
    width: 100%;
    padding: 14px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }