@import "../../globals/colors.module.scss";

.chatRoomWrapper {
    height: 100vh;
    z-index: 10;
    position: absolute;
    top: 0;
    background: var(--color-white);
    width: 100%;
    border-right: 1px solid var(--color-green);
}

.chatRoomHeader {
    background: var(--color-green);
    color: var(--color-white);
    display: flex;
    height: auto;
    padding: 20px 0;
    justify-content: center;
}

.chatRoomExit {
    position: absolute;
    left: 20px;
    top: 26px;
    transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  border: solid var(--color-white);
  border-width: 0 3px 3px 0;
  padding: 5px;
  cursor: pointer;
}

.chatRoomTitle {
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    width: 220px;
    text-align: center;
    text-overflow: ellipsis;
}

.messageHistoryWrapper {
    height: 710px;
    margin: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.senderMe {
    font-size: 0.8rem;
    color: black;
    padding-bottom: 4px;
}

.senderOther {
    font-size: 0.8rem;
    color: black;
    padding-bottom: 4px;
    margin-left: auto;
}

.messageMe {
    background-color: var(--color-blue);
    color: var(--color-white);
    padding: 10px;
    width: fit-content;
    border-radius: 12px;
    margin-bottom: 12px;
}

.messageOther {
    background-color: var(--color-green);
    color: var(--color-white);
    padding: 10px;
    width: fit-content;
    border-radius: 12px;
    margin-bottom: 12px;
    margin-left: auto;
}