@import '../../globals/colors.module.scss';

.chatPanelWrapper {
  background-color: var(--color-white);
  width: 100%;
  height: 100vh;
  display: flex;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
  position: relative;
}

.roomWrapper {
  display: flex;
  padding: 20px;
  margin: 20px;
  background: var(--color-blue);
  color: var(--color-white);
  font-size: 1rem;
}
