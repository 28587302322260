.imageWrapper {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  align-items: center;
  top: 38%;
  left: 32%;
}

.loadingImage {
  position: relative;
  display: flex;
  justify-content: center;
  .yellow {
    position: relative;
    left: 10px;
    z-index: 3;
    margin-bottom: 30px;
    height: 100px;
    width: 100px;
    background: url("../../img/yellowhex.png") no-repeat center center;
    background-size: 200px;
    animation: spin 6s ease-in-out infinite;
  }

  .green {
    position: absolute;
    z-index: 2;
    margin-bottom: 30px;
    height: 100px;
    width: 100px;
    background: url("../../img/greenhex.png") no-repeat center center;
    background-size: 200px;
    animation: spin 6s ease-in-out infinite;
  }

  .blue {
    position: absolute;
    left: -10px;
    z-index: 1;
    margin-bottom: 30px;
    height: 100px;
    width: 100px;
    background: url("../../img/bluehex.png") no-repeat center center;
    background-size: 200px;
    animation: spin 6s ease-in-out infinite;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
