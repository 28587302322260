@import "../../globals/colors.module.scss";

.searchContentWrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100vw;
    position: absolute;
    justify-content: center;
    top: 0;
    overflow: hidden;
    font-size: 1.2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.searchBarWrapper {
    display: flex;
    border-bottom: 1px solid #c2daff;
}

.searchBar {
    width: 100%;
    display: block;
    position: relative;
    height: 50px;
    background: #F5F9FF;
    border: unset;
    caret-color: var(--color-blue);

    &::-webkit-search-cancel-button{
        position:relative;
        right:14%;
      
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  background: url("../../img/cancel.png") no-repeat center center;
  background-size: 26px;
  transition: transform 400ms ease;
  &:hover {
      cursor: pointer;
  }
    }
}

.searchIconWrapper {
    height: 50px;
    width: 50px;
    background: #F5F9FF;
}

.searchIcon {
    content: '';
    background: url("../../img/search.png") no-repeat center center;
    background-size: 25px;
    height: 50px;
    width: 50px;
    transition: transform 400ms ease;
    &:hover {
        transform: rotate(-20deg);
        transition: transform 400ms ease;
    }
}

.usersWrapper {
    display: flex;
    flex-flow: column nowrap;
    background: #F5F9FF;
    width: 100%;
    overflow-x: auto;
    z-index: 10;
}

.searchContentTitle {
    padding: 20px;
    color: var(--color-blue);
    opacity: 0.7;
    font-size: 1.1rem;
    width: 100%;
}

.user, .userChat {
    padding: 20px 20px 20px 40px;
    color: var(--color-blue);
    cursor: pointer;
    transition: background 400ms ease;

    &:hover {
        background: #c2daff;
    }
}

.userChat::after {
    content: '';
    padding: 10px 20px;

}

textarea:focus, input:focus{
    outline: none;
}

.noResultWrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
}

.noResult {
    background: url("../../img/blank.svg") no-repeat center center;
    background-size: contain;
    height: 30%;
    width: 70%;
}

.noResultInfo {
    display: flex;
    padding: 30px;
    color: var(--color-blue);
    font-size: 1.4rem;
    font-weight: 600;
}