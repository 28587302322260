@import '../../globals/colors.module.scss';
@import '../../globals/fonts.module.scss';

.sideWrapper {
  color: var(--color-blue);
  display: block;
  height: 860px;
  overflow: hidden;
  position: relative;
  background: #fff;
  border-radius: 0 6px 6px 0;
  padding-top: 20px;
  width: 100%;
  transition: width 1s;
  font-family: var(--font-nunito);
}

.contentWrapper {
  display: flex;
  height: 100%;
  align-items: left;
  flex-flow: column;
  width: 100%;
  margin: 0 auto;
  max-width: 80%;
  transition: opacity 600ms;
  overflow: hidden;
}
